import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LegalInformationService {

    constructor() {

    }

    goLegal() {
        //Пользовательское соглашение
        window.open('https://xn--80aapgyievp4gwb.xn--p1ai/legal/marketterms.pdf');
    }

    goPrivacy() {
        //Политика конфедециальности
        window.open('https://xn--80aapgyievp4gwb.xn--p1ai/legal/privacypolicy.pdf');
    }


}
